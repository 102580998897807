import Storage from "@/plugins/storage";
import * as Sentry from "@sentry/browser";
import StitchService from "@/services/StitchService";
import { CookiesKeys } from "@/enums/Storage";
import { Environment } from "@/enums/Config";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import UtmService from "@/services/UtmService";
import ExperimentService from "./ExperimentService";
import { Variant } from "@/models/Experiments";

/* MOCKS */
jest.mock("@/services/MyRentalsConfig");
let location: any = window.location;
const mockLocation = new URL("https://housfy.com") as any;
const mockedUserAgent = "Mozilla 1234";
const mockedDevice = "desktop";
const mockedDate = "2022-06-21T10:34:46.538Z";

const mockedPayload = {
  user_id: undefined,
  user_uuid: undefined,
  admin_user_id: undefined,
  admin_user_uuid: undefined,
  browser_uuid: "browser1234uuid",
  domain: "localhost",
  full_url: "http://localhost/",
  product_name: "housfy",
  product_id: 1,
  area: "private_web",
  event_name: "page_view",
  event_detail: undefined,
  event_created_at: mockedDate,
  user_agent: mockedUserAgent,
  device_type: mockedDevice,
  utm_campaign: "(not set)",
  utm_content: undefined,
  utm_medium: "(none)",
  utm_source: "(direct)",
  utm_term: undefined,
  gclid: undefined,
};

beforeEach(() => {
  jest.restoreAllMocks();

  window.fetch = jest.fn(() => Promise.resolve({})) as jest.Mock;
  location = window.location;
  delete window[location];
  window.location = mockLocation;
  Object.defineProperty(window, "navigator", {
    value: {
      ...window.navigator,
      cookieEnabled: true,
      userAgent: mockedUserAgent,
    },
    writable: true,
  });
  document.device = mockedDevice;
});

afterEach(() => {
  window.location = location;
});

describe("StitchService tests", () => {
  describe("init", () => {
    it("should call eventListeners", () => {
      // given
      spyOn(document.body, "addEventListener");
      spyOn(window, "addEventListener");
      // when
      StitchService.init();
      // then
      expect(document.body.addEventListener).toHaveBeenCalled();
      expect(window.addEventListener).toHaveBeenCalled();
    });
    it("should call trackEvent if a click is been made", () => {
      // given
      spyOn(StitchService, "trackEvent");
      const element = document.createElement("div");
      document.body.appendChild(element);
      element.setAttribute("data-tracking", "test");
      // when
      StitchService.init();
      element.click();
      // then
      expect(StitchService.trackEvent).toHaveBeenCalled();
    });
    it("should send console.error and sentry when it catch an error", () => {
      // given
      const mockedTextMessage = "test message";
      spyOn(console, "error");
      spyOn(Sentry, "captureException");
      spyOn(StitchService, "trackEvent").and.throwError(mockedTextMessage);
      const element = document.createElement("div");
      document.body.appendChild(element);
      element.setAttribute("data-tracking", "test");
      // when
      StitchService.init();
      element.click();

      // then
      expect(console.error).toHaveBeenCalledWith(
        "[Stitch] Stitch Click tracking error"
      );
      expect(Sentry.captureException).toHaveBeenCalledWith(
        `[Stitch] Stitch Click tracking error Error: ${mockedTextMessage}`
      );
    });
  });
  describe("localStorageAvailabity", () => {
    it("should return if localStorage is available", () => {
      // given
      // when
      const result = StitchService.localStorageAvailability();
      // then
      expect(result).toBe(true);
    });
  });

  describe("getAndSetBrowserUuid", () => {
    it("should return id from cookies if it exists", () => {
      // given
      spyOn(Storage, "getCookiesItem").and.returnValue("2");
      // when
      // then
      expect(StitchService.getAndSetBrowserUuid()).toBe("2");
    });
    it("should generate a new uuid if it does not exist and return it", () => {
      // given
      const mockedUuid = "uuid1234";
      spyOn(Storage, "getCookiesItem").and.returnValue("");
      spyOn(Storage, "setCookiesItem");
      spyOn(StitchService, "generateUuid").and.returnValue(mockedUuid);
      // when
      StitchService.getAndSetBrowserUuid();
      // then
      expect(Storage.setCookiesItem).toHaveBeenCalledWith(
        CookiesKeys.BROWSER_UUID,
        mockedUuid
      );
    });
    it("should generate a new id if cookies are not available", () => {
      // given
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      spyOn(StitchService, "generateUuid");
      // when
      StitchService.getAndSetBrowserUuid();
      // then
      expect(StitchService.generateUuid).toHaveBeenCalled();
    });
  });

  describe("generateUuid", () => {
    it("should return a 36chars string", () => {
      // given
      const expectedLength = 36;
      // when
      const stringResult = StitchService.generateUuid();
      // then
      expect(stringResult.length).toBe(expectedLength);
    });
  });
  describe("trackEvent", () => {
    it("should return if eventName is not pageView and cookies are not available", () => {
      // given
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      spyOn(StitchService, "sendEvent");
      // when
      StitchService.trackEvent("click");
      // then
      expect(StitchService.sendEvent).not.toHaveBeenCalled();
    });
    it("should return if eventName is not pageView and localStorage are not available", () => {
      // given
      spyOn(StitchService, "localStorageAvailability").and.returnValue(false);
      spyOn(StitchService, "sendEvent");
      // when
      StitchService.trackEvent("click");
      // then
      expect(StitchService.sendEvent).not.toHaveBeenCalled();
    });
    it("should return if eventName is not pageView and sessionStorage are not available", () => {
      // given
      spyOn(StitchService, "sessionStorageAvailability").and.returnValue(false);
      spyOn(StitchService, "sendEvent");
      // when
      StitchService.trackEvent("click");
      // then
      expect(StitchService.sendEvent).not.toHaveBeenCalled();
    });

    it("should set variables as undefined and call the sendEventMethod if cookies and sessionStorage are not available", () => {
      // given
      const mockedBrowserUuid = "browser1234uuid";
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      spyOn(StitchService, "sessionStorageAvailability").and.returnValue(false);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        mockedBrowserUuid
      );
      spyOn(Date.prototype, "toISOString").and.returnValue(mockedDate);
      spyOn(StitchService, "sendEvent");
      const expectedPayload = {
        user_id: undefined,
        user_uuid: undefined,
        admin_user_id: undefined,
        admin_user_uuid: undefined,
        browser_uuid: mockedBrowserUuid,
        domain: "localhost",
        full_url: "http://localhost/",
        product_name: "housfy",
        product_id: 1,
        area: "private_web",
        event_name: "page_view",
        event_detail: undefined,
        event_created_at: mockedDate,
        user_agent: mockedUserAgent,
        device_type: mockedDevice,
        utm_campaign: "(not set)",
        utm_content: undefined,
        utm_medium: "(none)",
        utm_source: "(direct)",
        utm_term: undefined,
        gclid: undefined,
      };
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(expectedPayload);
    });

    it('should call "call" method  if eventName is passed as string with correct payload', () => {
      // given
      spyOn(Date.prototype, "toISOString").and.returnValue(mockedDate);
      spyOn(StitchService, "getExperiments").and.returnValue(undefined);
      spyOn(StitchService, "sendEvent");
      spyOn(document, "cookie").and.returnValue(true);
      let alreadyCalled = false;
      spyOn(Storage, "getLocalStorageItem").and.callFake(() => {
        if (alreadyCalled) return "uuid12334";
        alreadyCalled = true;
        return "1234";
      });
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        "browser1234uuid"
      );
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(mockedPayload);
    });

    it("should take the utms from cookies or url and send them in the payload", () => {
      // given

      const mockedPayloadWithUtms = {
        ...mockedPayload,
        utm_source: "test_source",
        utm_medium: "test_medium",
        utm_campaign: "test_campaign",
        utm_term: "test_term",
        utm_content: "test_content",
        gclid: "test_gclid",
      };

      spyOn(UtmService, "getUtmsFromUrlOrCookie").and.returnValue({
        utmSource: "test_source",
        utmMedium: "test_medium",
        utmCampaign: "test_campaign",
        utmTerm: "test_term",
        utmContent: "test_content",
        googleClientId: "test_gclid",
      });

      spyOn(StitchService, "sendEvent");
      spyOn(StitchService, "getExperiments").and.returnValue(undefined);
      let alreadyCalled = false;
      spyOn(Storage, "getLocalStorageItem").and.callFake(() => {
        if (alreadyCalled) return "uuid12334";
        alreadyCalled = true;
        return "1234";
      });
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        "browser1234uuid"
      );
      spyOn(Date.prototype, "toISOString").and.returnValue(
        "2022-06-21T10:34:46.538Z"
      );
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(
        mockedPayloadWithUtms
      );
    });

    it("should take the utms from url and send them in the payload if cookies are not available", () => {
      // given

      const mockedPayloadWithUtms = {
        ...mockedPayload,
        utm_source: "test_source",
        utm_medium: "test_medium",
        utm_campaign: "test_campaign",
        utm_term: "test_term",
        utm_content: "test_content",
        gclid: "test_gclid",
      };
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      spyOn(UtmService, "extractUTMSfromURL").and.returnValue({
        utmSource: "test_source",
        utmMedium: "test_medium",
        utmCampaign: "test_campaign",
        utmTerm: "test_term",
        utmContent: "test_content",
        googleClientId: "test_gclid",
      });

      spyOn(StitchService, "sendEvent");
      let alreadyCalled = false;
      spyOn(Storage, "getLocalStorageItem").and.callFake(() => {
        if (alreadyCalled) return "uuid12334";
        alreadyCalled = true;
        return "1234";
      });
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        "browser1234uuid"
      );
      spyOn(Date.prototype, "toISOString").and.returnValue(
        "2022-06-21T10:34:46.538Z"
      );
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(
        mockedPayloadWithUtms
      );
    });
    it("should set generic utms and send them in the payload if cookies are not available and url doesn't have anyone", () => {
      // given
      const mockedPayloadWithUtms = {
        ...mockedPayload,
        utm_campaign: "(not set)",
        utm_content: undefined,
        utm_medium: "(none)",
        utm_source: "(direct)",
        utm_term: undefined,
        gclid: undefined,
      };
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      spyOn(UtmService, "extractUTMSfromURL").and.returnValue({});

      spyOn(StitchService, "sendEvent");
      let alreadyCalled = false;
      spyOn(Storage, "getLocalStorageItem").and.callFake(() => {
        if (alreadyCalled) return "uuid12334";
        alreadyCalled = true;
        return "1234";
      });
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        "browser1234uuid"
      );
      spyOn(Date.prototype, "toISOString").and.returnValue(
        "2022-06-21T10:34:46.538Z"
      );
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(
        mockedPayloadWithUtms
      );
    });

    it("should catch error and send a console.error and a sentry exception", () => {
      // given
      const eventName = "page_view";
      const errorMessage = "error test";
      spyOn(console, "error");
      spyOn(Sentry, "captureException");
      spyOn(StitchService, "getAndSetBrowserUuid").and.throwError(errorMessage);
      // when
      StitchService.trackEvent(eventName);
      // then
      expect(console.error).toHaveBeenCalledWith(
        `[Stitch] Stitch ${eventName} error`
      );
      expect(Sentry.captureException).toHaveBeenCalledWith(
        `[Stitch] Stitch ${eventName} error Error: ${errorMessage}`
      );
    });
    it("should add to the payload correct experiments string if there is one active", () => {
      // given
      const mockedExperiment = {
        name: "MYH_TEST",
        id: "fake-id",
        variant: "A" as Variant,
      };
      const experimentsString = `${mockedExperiment.id}_-_${mockedExperiment.name}_-_${mockedExperiment.variant}`;
      const mockedPayloadWithExp = {
        ...mockedPayload,
        utm_campaign: "(not set)",
        utm_content: undefined,
        utm_medium: "(none)",
        utm_source: "(direct)",
        utm_term: undefined,
        gclid: undefined,
        experiments: experimentsString,
      };
      spyOn(StitchService, "cookiesAvailability").and.returnValue(true);
      spyOn(UtmService, "extractUTMSfromURL").and.returnValue({});

      spyOn(StitchService, "sendEvent");
      let alreadyCalled = false;
      spyOn(Storage, "getLocalStorageItem").and.callFake(() => {
        if (alreadyCalled) return "uuid12334";
        alreadyCalled = true;
        return "1234";
      });
      spyOn(Storage, "getSessionStorageItem").and.returnValue(null);
      spyOn(StitchService, "getAndSetBrowserUuid").and.returnValue(
        "browser1234uuid"
      );
      spyOn(Date.prototype, "toISOString").and.returnValue(
        "2022-06-21T10:34:46.538Z"
      );
      jest
        .spyOn(ExperimentService, "activeExperiments", "get")
        .mockReturnValue([mockedExperiment]);
      // when
      StitchService.trackEvent("page_view");
      // then
      expect(StitchService.sendEvent).toHaveBeenCalledWith(
        mockedPayloadWithExp
      );
    });
    it("should call getActivesExperiments when it passes initial conditionals", () => {
      // given
      spyOn(StitchService, "getExperiments");
      spyOn(StitchService, "cookiesAvailability").and.returnValue(true);
      spyOn(StitchService, "localStorageAvailability").and.returnValue(true);
      spyOn(StitchService, "sessionStorageAvailability").and.returnValue(true);
      // when
      StitchService.trackEvent("test");
      // then
      expect(StitchService.getExperiments).toHaveBeenCalledTimes(1);
    });
  });
  describe("sendEvent", () => {
    it("should call debugStitchEvent method if env is no production", () => {
      // given
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.LOCAL,
      });
      spyOn(console, "error");
      spyOn(Sentry, "captureException");
      spyOn(StitchService, "debugStitchEvent");
      // when
      StitchService.sendEvent(mockedPayload);
      // then
      expect(StitchService.debugStitchEvent).toHaveBeenCalledWith(
        mockedPayload.event_name,
        mockedPayload.event_detail
      );
    });
    it("should catch error", async () => {
      // given
      spyOn(console, "error");
      spyOn(Sentry, "captureException");
      // when
      window.fetch = jest.fn(() => Promise.reject("Fetch error")) as jest.Mock;

      await StitchService.sendEvent(mockedPayload);
      // then
      expect(console.error).toHaveBeenCalledWith("[Stitch] Fetch error");
    });
  });
  describe("debugStitchEvent", () => {
    it("should not call console.info if it is production", () => {
      // given
      spyOn(console, "info");
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.PROD,
      });
      const eventName = "page_view";
      // when
      StitchService.debugStitchEvent(eventName);
      // then
      expect(console.info).not.toHaveBeenCalled();
    });
    it("should call console.info if it is not production", () => {
      // given
      spyOn(console, "info");
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.LOCAL,
      });
      const eventName = "page_view";
      const eventDetail = undefined;
      const debugString = `[Stitch] Stitch ${eventName} ${eventDetail}`;
      // when
      StitchService.debugStitchEvent(eventName);
      // then
      expect(console.info).toHaveBeenCalledWith(debugString);
    });
  });

  describe("getActiveExperiments", () => {
    it("should return undefined if cookiesAvailability is false", () => {
      // given
      spyOn(StitchService, "cookiesAvailability").and.returnValue(false);
      // when
      const result = StitchService.getExperiments();
      // then
      expect(result).toBe(undefined);
    });
    it("should return undefined if there are not activeExperiments", () => {
      // given
      jest
        .spyOn(ExperimentService, "activeExperiments", "get")
        .mockReturnValue([]);
      // when
      const result = StitchService.getExperiments();
      // then
      expect(result).toBe(undefined);
    });
    it("should return correct string if there is 1 active experiment", () => {
      // given
      const mockedId = "asd-12331-ddd";
      const mockedName = "MYH_TEST";
      const mockedExperiment = {
        id: mockedId,
        name: mockedName,
        variant: "B" as Variant,
      };
      const expectedResult = `${mockedId}_-_${mockedName}_-_${mockedExperiment.variant}`;
      jest
        .spyOn(ExperimentService, "activeExperiments", "get")
        .mockReturnValue([mockedExperiment]);
      // when
      const result = StitchService.getExperiments();
      // then
      expect(result).toBe(expectedResult);
    });
    it("should return correct string if there are more than 1 active experiment", () => {
      // given
      const mockedExperiment1 = {
        id: "asd-12331-ddd",
        name: "MYH_TEST",
        variant: "B" as Variant,
      };
      const mockedExperiment2 = {
        id: "asd-457777-ddd",
        name: "MYH_TEST_2",
        variant: "C" as Variant,
      };
      const expectedResult = `${mockedExperiment1.id}_-_${mockedExperiment1.name}_-_${mockedExperiment1.variant};${mockedExperiment2.id}_-_${mockedExperiment2.name}_-_${mockedExperiment2.variant}`;
      jest
        .spyOn(ExperimentService, "activeExperiments", "get")
        .mockReturnValue([mockedExperiment1, mockedExperiment2]);
      // when
      const result = StitchService.getExperiments();
      // then
      expect(result).toBe(expectedResult);
    });
  });
});
